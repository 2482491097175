import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import { GuaranteeContainer, Heading, Subheader, GuaranteeImageContainer, TextBox } from './styles'

const HomepageGuarantee = () => {
  const { moneyBack } = useStaticQuery(graphql`
    query HomepageGuaranteeQuery {
      moneyBack: file(relativePath: { eq: "homepage/money-back.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <GuaranteeContainer>
      <GuaranteeImageContainer>
        <GatsbyImage image={moneyBack.childImageSharp.gatsbyImageData} alt="Money back Guarantee" />
      </GuaranteeImageContainer>
      <TextBox>
        <Heading>100% Satisfaction Guaranteed</Heading>
        <Subheader>We're confident that you'll love your Skylight, or your money back!</Subheader>
      </TextBox>
    </GuaranteeContainer>
  )
}

export default HomepageGuarantee
