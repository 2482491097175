import React, { Component } from 'react'
import Slider from 'react-slick'
import PropTypes from 'prop-types'
import { CAROUSEL_CONSTANTS } from './constants'
import {
  CustomerName,
  Text,
  Card,
  ArrowContainer,
  Arrow,
  Container,
  SliderContainer,
  LeftArrow,
  RightArrow,
  Star,
  StarContainer,
} from '../FrameTestimonials/styles'

class Carousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }

  render() {
    const { backImg, forwardImg, star } = this.props

    return (
      <SliderContainer>
        <LeftArrow>
          <ArrowContainer onClick={this.previous}>
            <Arrow image={forwardImg.childImageSharp.gatsbyImageData} alt="Back Arrow" />
          </ArrowContainer>
        </LeftArrow>
        <Slider
          ref={(c) => (this.slider = c)}
          infinite
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          initialSlide={0}
          responsive={[
            {
              breakpoint: 600,
              settings: {
                arrows: false,
                centerPadding: '60px',
                slidesToShow: 1,
              },
            },
          ]}
        >
          {CAROUSEL_CONSTANTS.map((review, i) => (
            <React.Fragment key={i}>
              <Container>
                <Card>
                  <Text className="text">{review.text}</Text>
                  <StarContainer>
                    <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
                    <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
                    <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
                    <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
                    <Star image={star.childImageSharp.gatsbyImageData} alt="Star rating" />
                    <CustomerName>{review.name}</CustomerName>
                  </StarContainer>
                </Card>
              </Container>
            </React.Fragment>
          ))}
        </Slider>
        <RightArrow>
          <ArrowContainer onClick={this.next}>
            <Arrow image={backImg.childImageSharp.gatsbyImageData} alt="Forward Arrow" />
          </ArrowContainer>
        </RightArrow>
      </SliderContainer>
    )
  }
}
Carousel.propTypes = {
  forwardImg: PropTypes.object.isRequired,
  backImg: PropTypes.object.isRequired,
  star: PropTypes.object.isRequired,
}
export default Carousel
