import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'
import { GatsbyImage } from 'gatsby-plugin-image'

export const Section = styled.div`
  padding: 30px 0px 40px;
  max-width: 1400px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    padding: 0px 0px 40px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-bottom: 20px;
    .slick-slide > div {
      min-height: 200px;
      display: flex;
      justify-content: center;
    }
  }
`
export const Header = styled.h1`
  text-align: center;
  margin: 0px auto;
  width: 90%;
  color: ${(props) => props.theme.grayDark};
  font-size: 26px;
  line-height: 32px;
  @media (max-width: ${breakpoints.m}px) {
    font-size: 18px;
  }
`
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0px 50px;
`

export const Text = styled.h2`
  max-width: 780px;
  font-size: 26px;
  line-height: 32px;
  text-align: center;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    max-width: 80%;
    margin: auto;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 20px;
    max-width: 90%;
  }
  @media (max-width: 360px) {
    font-size: 16px;
    line-height: 1%.2;
  }
`

export const Card = styled.div`
  position: relative;
  padding-top: 20px;
  margin-top: 0px;
  @media (max-width: ${breakpoints.l}px) {
    margin-bottom: 0px;
    padding: 0px;
  }
`
export const CustomerName = styled.p`
  font-size: 16px;
  margin-left: 16px;
  @media (max-width: ${breakpoints.s}px) {
    min-width: 100%;
    text-align: center;
  }
`

export const ArrowContainer = styled.div`
  cursor: pointer;
`

export const Arrow = styled(GatsbyImage)``

export const SliderContainer = styled.div`
  position: relative;
  z-index: 2;
`
export const LeftArrow = styled.div`
  position: absolute;
  top: 15%;
  left: 50px;
  z-index: 10;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (max-width: ${breakpoints.l}px) {
    left: 30px;
    top: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    left: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    top: 40px;
  }
  @media (max-width: 400px) {
    left: 5px;
    top: 70px;
  }
`
export const RightArrow = styled.div`
  position: absolute;
  top: 15%;
  right: 50px;
  z-index: 10;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  @media (max-width: ${breakpoints.l}px) {
    right: 30px;
    top: 20px;
  }
  @media (max-width: ${breakpoints.m}px) {
    right: 20px;
  }
  @media (max-width: ${breakpoints.s}px) {
    top: 40px;
  }
  @media (max-width: 400px) {
    right: 5px;
    top: 70px;
  }
`
export const Star = styled(GatsbyImage)``

export const StarContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0px;
`
