const TESTIMONIALS = {
  erica: `“I love sharing pictures of my kids with multiple family members all at once. I don’t have to send multiple texts or emails and risk forgetting someone!”`,
  paul: `“Excellent addition to the living room, keeps those fond memories scrolling.”`,
  millie: `“Couldn't be happier with the results of sending this frame loaded with pictures to my step-mom.”`,
  emily: `“Our family is seriously obsessed! It started with gifts to my parents and grandparents, and now I have purchased 7!!! Everyone loves them so much!”`,
  susan: `“It’s like visiting with family every day.”`,
  terry: `"It is WORTH the money to help organize your digital and photo clutter."`,
  timothy: `"I have dementia and seeing pictures of my family has made all the difference helping me keep my memories alive."`,
  amy: `"I purchased a Skylight to be able to enjoy all of our wedding photos in one frame. Now we can relive the fun and love of our special day EVERY DAY!"`,
}
export const CAROUSEL_CONSTANTS = [
  {
    text: TESTIMONIALS.erica,
    name: '- Erica T.',
  },
  {
    text: TESTIMONIALS.paul,
    name: '- Paul L.',
  },
  {
    text: TESTIMONIALS.millie,
    name: '- Millie S.',
  },
  {
    text: TESTIMONIALS.emily,
    name: '- Emily L.',
  },
  {
    text: TESTIMONIALS.susan,
    name: '- Susan H.',
  },
  {
    text: TESTIMONIALS.terry,
    name: '- Terry B.',
  },
  {
    text: TESTIMONIALS.timothy,
    name: '- Timothy T.',
  },
  {
    text: TESTIMONIALS.amy,
    name: '- Amy P.',
  },
]
