import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Section, Header } from './styles'
import Carousel from '../Carousel'

const FrameTestimonials = () => {
  const { forwardImg, backImg, star } = useStaticQuery(graphql`
    query FrameTestimonialsQuery {
      forwardImg: file(relativePath: { eq: "chevron-left.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: NONE, width: 40)
        }
      }
      backImg: file(relativePath: { eq: "chevron-right.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: NONE, width: 40)
        }
      }
      star: file(relativePath: { eq: "star.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FIXED, placeholder: NONE, width: 27)
        }
      }
    }
  `)
  return (
    <Section>
      <Header>What our customers say</Header>
      <Carousel forwardImg={forwardImg} backImg={backImg} star={star} />
    </Section>
  )
}

export default FrameTestimonials
