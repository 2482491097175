import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  padding: 40px 0px;
  position: relative;
  background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.cream)};
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px 60px;
  }
`
export const Header = styled.h1`
  font-size: 26px;
  line-height: 32px;
  margin: 0px 0px 40px;
  text-align: center;
  @media (max-width: ${breakpoints.m}px) {
    margin: 0px 0px 10px;
    font-size: 18px;
    line-height: 32px;
  }
`
